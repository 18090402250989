import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const PortfolioImage = props => {
  const data = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "portfolioimages" } }
        ) {
          edges {
            node {
              name
              relativePath
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationHeight
                  presentationWidth
                }
              }
            }
          }
        }
      }
    `
  )

  //   const imageData = data.desktop.childImageSharp.fluid

  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(props.filename)
  })
  if (!image) {
    return <div>Nothing Found</div>
  }

  return (
    <a
      class='spotlight'
      href={image.node.childImageSharp.fluid.src}
      data-title={props.title}
      data-size={`${image.node.childImageSharp.fluid.presentationWidth}x${image.node.childImageSharp.fluid.presentationHeight}`}
    >
      <Img alt={props.alt} fluid={image.node.childImageSharp.fluid} />
    </a>
  )
}

export default PortfolioImage
