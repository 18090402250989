import React, { useEffect } from 'react'
import PortfolioImage from '../DynamicImageComponents/PortfolioImage'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

const Portfoliogrid = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // const Spotlight = require('spotlight.js/src/js/spotlight')
      // console.log(`Spotlight ${window.Spotlight}`)
      // window.Spotlight.init()
      require([
        'photoswipe',
        'photoswipe/dist/photoswipe-ui-default'
      ], function (PhotoSwipe, PhotoSwipeUI_Default) {
        // console.log(`PhotoSwipeJS ${PhotoSwipe}`)
        var initPhotoSwipeFromDOM = function (gallerySelector) {
          // parse slide data (url, title, size ...) from DOM elements
          // (children of gallerySelector)
          var parseThumbnailElements = function (el) {
            var thumbElements = el.childNodes,
              numNodes = thumbElements.length,
              items = [],
              figureEl,
              linkEl,
              size,
              item

            for (var i = 0; i < numNodes; i++) {
              figureEl = thumbElements[i] // <figure> element

              // include only element nodes
              if (figureEl.nodeType !== 1) {
                continue
              }

              linkEl = figureEl.children[0] // <a> element

              size = linkEl.getAttribute('data-size').split('x')

              // create slide object
              item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
              }

              if (figureEl.children.length > 1) {
                // <figcaption> content

                item.title = figureEl.children[1].innerHTML
              }

              if (linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                // item.msrc = linkEl.children[0].getAttribute('src')
                item.msrc = linkEl
                  .getElementsByTagName('img')[1]
                  .getAttribute('src')
                // item.msrc = linkEl.getAttribute('href')
              }

              item.el = figureEl // save link to element for getThumbBoundsFn
              items.push(item)
            }

            return items
          }

          // find nearest parent element
          var closest = function closest (el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn))
          }

          // triggers when user clicks on thumbnail
          var onThumbnailsClick = function (e) {
            e = e || window.event
            e.preventDefault ? e.preventDefault() : (e.returnValue = false)

            var eTarget = e.target || e.srcElement

            // find root element of slide
            var clickedListItem = closest(eTarget, function (el) {
              return el.tagName && el.tagName.toUpperCase() === 'FIGURE'
            })

            if (!clickedListItem) {
              return
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute

            var clickedGallery = clickedListItem.parentNode,
              childNodes = clickedListItem.parentNode.childNodes,
              numChildNodes = childNodes.length,
              nodeIndex = 0,
              index

            console.log(childNodes)

            for (var i = 0; i < numChildNodes; i++) {
              console.log(childNodes[i] === clickedListItem)
              console.log(childNodes[i])

              if (childNodes[i].nodeType !== 1) {
                continue
              }

              if (childNodes[i] === clickedListItem) {
                index = nodeIndex

                break
              }
              nodeIndex++
            }

            if (index >= 0) {
              // open PhotoSwipe if valid index found
              // console.log(clickedGallery)
              openPhotoSwipe(index, clickedGallery)
            }
            return false
          }

          // parse picture index and gallery index from URL (#&pid=1&gid=2)
          var photoswipeParseHash = function () {
            var hash = window.location.hash.substring(1),
              params = {}

            if (hash.length < 5) {
              return params
            }

            var vars = hash.split('&')
            for (var i = 0; i < vars.length; i++) {
              if (!vars[i]) {
                continue
              }
              var pair = vars[i].split('=')
              if (pair.length < 2) {
                continue
              }
              params[pair[0]] = pair[1]
            }

            if (params.gid) {
              params.gid = parseInt(params.gid, 10)
            }

            return params
          }

          var openPhotoSwipe = function (
            index,
            galleryElement,
            disableAnimation,
            fromURL
          ) {
            var pswpElement = document.querySelectorAll('.pswp')[0],
              gallery,
              options,
              items

            items = parseThumbnailElements(galleryElement)
            console.log(items)

            // define options (if needed)
            options = {
              // define gallery index (for URL)
              galleryUID: galleryElement.getAttribute('data-pswp-uid'),

              getThumbBoundsFn: function (index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[1], // find thumbnail
                  pageYScroll =
                    window.pageYOffset || document.documentElement.scrollTop,
                  rect = thumbnail.getBoundingClientRect()
                console.log(rect)

                return {
                  x: rect.left,
                  y: rect.top + pageYScroll,
                  w: rect.width
                }
              }
            }

            // PhotoSwipe opened from URL
            if (fromURL) {
              if (options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for (var j = 0; j < items.length; j++) {
                  if (items[j].pid == index) {
                    options.index = j
                    break
                  }
                }
              } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1
              }
            } else {
              options.index = parseInt(index, 10)
            }

            // exit if index not found
            if (isNaN(options.index)) {
              return
            }

            if (disableAnimation) {
              options.showAnimationDuration = 0
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(
              pswpElement,
              PhotoSwipeUI_Default,
              items,
              options
            )
            console.log(gallery)
            gallery.init()
          }

          // loop through all gallery elements and bind events
          var galleryElements = document.querySelectorAll(gallerySelector)

          for (var i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i + 1)
            galleryElements[i].onclick = onThumbnailsClick
          }

          // Parse URL and open gallery if it contains #&pid=3&gid=1
          var hashData = photoswipeParseHash()

          if (hashData.pid && hashData.gid) {
            openPhotoSwipe(
              hashData.pid,
              galleryElements[hashData.gid - 1],
              true,
              true
            )
          }
        }

        // execute above function
        initPhotoSwipeFromDOM('.portfolio-grid-comp')
      })
    }
  })
  return (
    <>
      <div
        className='portfolio-grid-comp w-full grid gap-2 '
        itemscope
        itemtype='http://schema.org/ImageGallery'
      >
        {/* <div className='portfolio-item grid-gallery-big'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-big'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='sociallipstick-co-uk.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
        {/* <div className='portfolio-item grid-gallery-vertical'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-vertical'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='artofgrit-net.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
        {/* <div className='portfolio-item grid-gallery-big'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-big'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='blakespuzzles.com.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
        {/* <div className='portfolio-item grid-gallery-big'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-big'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='circle-harvest.myshopify.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
        {/* <div className='portfolio-item grid-gallery-vertical'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-vertical'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='desktopNutradapt.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
        {/* <div className='portfolio-item grid-gallery-horizontal'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-horizontal'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='onlockcase.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
        {/* <div className='portfolio-item grid-gallery-vertical'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-vertical'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='qbpowerbands.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
        {/* <div className='portfolio-item grid-gallery-big'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-big'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='queerlit-co-uk.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
        {/* <div className='portfolio-item grid-gallery-vertical'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-vertical'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='thetigerlilylashes.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
        {/* <div className='portfolio-item grid-gallery-vertical'> */}
        {/* <div style={{backgroundImage: `url(${PortfolioOne})`}}></div> */}
        <figure
          className='portfolio-item grid-gallery-vertical'
          itemprop='associatedMedia'
          itemscope
          itemtype='http://schema.org/ImageObject'
        >
          <PortfolioImage
            filename='www.haystacktechnologies.com.jpg'
            alt='Social Lipstick'
            title='Digital Agency'
          />
        </figure>
        {/* </div> */}
      </div>
      <div className='pswp' tabindex='-1' role='dialog' aria-hidden='true'>
        {/* <!-- Background of PhotoSwipe. 
         It's a separate element, as animating opacity is faster than rgba(). --> */}
        <div className='pswp__bg'></div>

        {/* <!-- Slides wrapper with overflow:hidden. --> */}
        <div className='pswp__scroll-wrap'>
          {/* <!-- Container that holds slides. PhotoSwipe keeps only 3 slides in DOM to save memory. -->
        <!-- don't modify these 3 pswp__item elements, data is added later on. --> */}
          <div className='pswp__container'>
            <div className='pswp__item'></div>
            <div className='pswp__item'></div>
            <div className='pswp__item'></div>
          </div>

          {/* <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. --> */}
          <div className='pswp__ui pswp__ui--hidden'>
            <div className='pswp__top-bar'>
              {/* <!--  Controls are self-explanatory. Order can be changed. --> */}

              <div className='pswp__counter'></div>

              <button
                className='pswp__button pswp__button--close'
                title='Close (Esc)'
              ></button>

              <button
                className='pswp__button pswp__button--share'
                title='Share'
              ></button>

              <button
                className='pswp__button pswp__button--fs'
                title='Toggle fullscreen'
              ></button>

              <button
                className='pswp__button pswp__button--zoom'
                title='Zoom in/out'
              ></button>

              {/* <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                <!-- element will get className pswp__preloader--active when preloader is running --> */}
              <div className='pswp__preloader'>
                <div className='pswp__preloader__icn'>
                  <div className='pswp__preloader__cut'>
                    <div className='pswp__preloader__donut'></div>
                  </div>
                </div>
              </div>
            </div>

            <div className='pswp__share-modal pswp__share-modal--hidden pswp__single-tap'>
              <div className='pswp__share-tooltip'></div>
            </div>

            <button
              className='pswp__button pswp__button--arrow--left'
              title='Previous (arrow left)'
            ></button>

            <button
              className='pswp__button pswp__button--arrow--right'
              title='Next (arrow right)'
            ></button>

            <div className='pswp__caption'>
              <div className='pswp__caption__center'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Portfoliogrid
